<template>
  <div>

    <!-- 2021-01-09 13:00:00 GMT -->
        <flip-countdown @timeElapsed="timeElapsed" deadline="2021-01-09 13:00:00 GMT"></flip-countdown>
  </div>
</template>

<script>
  import FlipCountdown from 'vue2-flip-countdown'


export default {
  components: { FlipCountdown },
  name: 'Countdown',
   props: {
    timeElapsed: Function
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
