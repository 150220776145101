<template>
  <div id="app">
    <div id="mainBg"></div>
    <div id="mainBgCover"></div>
    <div id="mainBgGradient"></div>
      <img class="logo" src="./assets/logo.png">

    <div class="loader"><img src="./assets/small-diamond.png"/>
Loading...</div>
      <div class="infoPanel" v-if="currentBox">
            <div class="gift-1">
          
                <div class="right-col" v-if="currentBox === '1'"><br><br>
                  <h1><span class="confetti" @mouseover="startConfetti"
          @mouseleave="stopConfetti">🎉</span> GIFT 1<br> #WorldDownload</h1><span class="hide-mobile"><br></span>
                  <p>We heard you!<br><br>Explore our much loved second world map. The grand city of Imperia and full world is beautifully preserved as it was the day the server closed on December 4th 2017.<br><br> We recommend playing in Creative, as there's too much to explore by foot!</p>
                    <br>
                  <a href="https://drive.google.com/file/d/1Lo1vFDbjJdoPRZ33ZBCBWJ9MUJ8YfJTb/view?usp=sharing" target="_blank"><div class="download-button">GET IMPERIA MEDIEVAL <small>(3.5 GB)</small></div></a>
                  <div class="box-button" @click="openAnotherBox">Open another box</div> 
                </div>


                <div class="right-col" v-if="currentBox === '2'"><br><br>
                  <h1><span class="confetti" @mouseover="startConfetti"
          @mouseleave="stopConfetti">🎉</span> GIFT 2<br> We found it!</h1><span class="hide-mobile"><br></span>
                  <p> <span class="hide-mobile">It was almost lost forever. We had to dig through some old boxes, dust off a few hard drives, and battle corrupted zip files - but here it is, and it's our second gift to you.<br><br></span>Explore our legendary Ancient world. This map is now 10 years old (it really is ancient!) and features some of the grandest creations ever built in Minecraft. The ancient city and spawn is preserved as it was the day it closed in 2015 and includes the must-see sites of the Colosseeum and Circus Maximus, as well as the Bisilica San Marco and St Marks Square.</p>
                    <br>
                  <a href="https://drive.google.com/file/d/1dANcFeYF_8JMGvafHKwXVVz6d4fO-xgF/view?usp=sharing" target="_blank"><div class="download-button">GET ANCIENT IMPERIA <small>(5.5 GB)</small></div></a>
                  <div class="box-button" @click="openAnotherBox">Open another box</div> 
                </div>


                          <div class="right-col" v-if="currentBox === '3'"><br><br>
                  <h1><span class="confetti" @mouseover="startConfetti"
          @mouseleave="stopConfetti">🎉</span> GIFT 3<br> PLAY.EMPIRELEGACY.ORG</h1><span class="hide-mobile"><br></span>
                  <p>Our biggest gift of all is the launch of a brand new public server. We are launching today with a fresh survival world, on a new IP: play.empirelegacy.org and we are live RIGHT NOW!<br><br>

The great news is, we're also hosting both our old worlds inside this new server, perfectly preserved for users to explore in Creative mode - so you can explore them again right now, and relive the memories together with old friends. The old worlds can't be edited, to preserve everything just how you remember it.<br><br>


Join us now for our first day launch. <br>We can't wait to see you there! </p>
                    <br>
                 <div class="download-button" @click="copyIp" v-clipboard:copy="serverIp">PLAY.EMPIRELEGACY.ORG <br><small v-if="!copied">(CLICK TO COPY IP TO CLIPBOARD)</small><small v-if="copied">(COPIED!)</small></div>
                  <div class="box-button" @click="openAnotherBox">Open another box</div> <br><br><br><br>
                </div>
            </div>
      </div>


      <div class="giftWrapper" v-if="countdownComplete">
          <Gift 
          :openBoxParent="openBox"
          :canOpenBox="allowBoxOpen"
          class="gift box-1" boxNumber="1"
          :flip="false"/>
          <Gift
          :openBoxParent="openBox"
          :canOpenBox="allowBoxOpen"
          class="gift box-2" boxNumber="2"
          :flip="false"/>
          <Gift 
          :openBoxParent="openBox"
          :canOpenBox="allowBoxOpen"
          class="gift box-3" boxNumber="3"
          :flip="true"/>
      </div>


        <div class="refresh-button" v-if="boxesOpened === 3" @click="reload">REFRESH BOXES</div>

      <div class="content">
      <AnniversaryMessage msg="10th Anniversary Announcement"/>
      <Countdown :timeElapsed="timeElapsedHandler"/>
      </div>

  </div>
</template>

<script>
import AnniversaryMessage from './components/AnniversaryMessage.vue'
import Countdown from './components/Countdown.vue'
import Gift from './components/Gift.vue'



export default {
  name: 'App',
  components: {
    AnniversaryMessage,
    Countdown,
    Gift
  },
  data: function() {
return {
  copied: false,
  serverIp: 'play.empirelegacy.org',
  boxesOpened: 0,
  currentBox: null,
  allowBoxOpen: true,
  countdownComplete: false,
    backgrounds: ["https://i.imgur.com/S17UtS1.png",
"https://i.imgur.com/kUNOax8.png",
"https://i.imgur.com/k8TFO5A.png",
"https://i.imgur.com/VoePnV2.png",
"https://i.imgur.com/tnjlNvP.png",
"https://i.imgur.com/NPw6Fvq.png",
"https://i.imgur.com/CjUXFwf.png",
"https://i.imgur.com/8G5k51Q.png",
"https://i.imgur.com/7VvMeAu.png",
"https://i.imgur.com/o2aTor1.png",
"https://i.imgur.com/xpkc0IN.png",
"https://i.imgur.com/2xjZt9X.png",
"https://i.imgur.com/CHtjA67.png",
"https://i.imgur.com/1U0nSUN.png",
"https://i.imgur.com/lxVT16i.png"]
  }
},
mounted() {

  let randomBackground = (Math.floor(Math.random()*this.backgrounds.length));
  let backgroundImage = new Image();
  backgroundImage.src = this.backgrounds[randomBackground];
  

  let countdownDate = new Date('2021-01-09 13:00:00 GMT');

  if( new Date().getTime() >= new Date(countdownDate).getTime()) {
    this.countdownComplete = true;
     setTimeout( () => {
    this.hideCountdown()
     }, 1000)
  }


  //FORCE BOXES
   this.countdownComplete = true;
  setTimeout( () => {
      this.hideCountdown()
    }, 1000)
  // END FORCE BOXES

  backgroundImage.onload = function() {


      document.querySelector('.loader').style.opacity = 0;


    setTimeout( () => {

      document.querySelector('.logo').style.opacity = 1;
      document.querySelector('#mainBgCover').style.opacity = 0;
      document.querySelector('#mainBg').style.backgroundPosition = 'center center';
                      }, 1000)
   
  }
  document.querySelector('#mainBg').style.backgroundImage = `url(${backgroundImage.src})`;

},
methods: {
  copyIp() {
    this.copied = true
  },
  timeElapsedHandler() {
    location.reload();
  },
  hideCountdown() {
        // this.countdownComplete = true;

          document.querySelector('#mainBg').style.transition = '0s';
          document.querySelector('body').style.overflowY = 'auto!important';
          document.querySelector('html').style.overflowY = 'auto!important';
          document.querySelector('#mainBg').style.transform = 'scale(1.9)';
          document.querySelector('.content').style.display = 'none';

          document.querySelector('.giftWrapper').style.opacity = 1;
          // document.querySelector('.giftWrapper').style.display = 'block';
          document.querySelector('.box-1').style.opacity = 0;
          document.querySelector('.box-2').style.opacity = 0;
          document.querySelector('.box-3').style.opacity = 0;

    setTimeout( () => {
          document.querySelector('.box-1').style.opacity = 1;
    }, 2500)
    setTimeout( () => {
          document.querySelector('.box-2').style.opacity = 1;
    }, 3000)
    setTimeout( () => {
          document.querySelector('.box-3').style.opacity = 1;
    }, 3500)

  },
  reload() {
    location.reload()
  },
  startConfetti() {
   this.$confetti.start()
  },
  stopConfetti() {
   this.$confetti.stop()
  },
  openAnotherBox() {
    
    this.boxesOpened++;

    // if (this.boxesOpened === 3) {  
    //   document.querySelector('.refresh-button').style.opacity = 1;
    // }

    document.querySelector('.infoPanel').style.opacity = 0;
    document.querySelector('.box-1').style.opacity = 1;
    document.querySelector('.box-2').style.opacity = 1;
    document.querySelector('.box-3').style.opacity = 1;
    document.querySelector('.logo').style.opacity = 1;
    document.querySelector(`.box-${this.currentBox}`).style.filter = 'grayscale(100%)'

    
    // document.querySelector(`.box-${this.currentBox}`).style.transform = 'scale(0.8)';

    setTimeout( () => {
                   
    this.currentBox = null;
    this.allowBoxOpen = true;

  if (document.querySelector('.infoPanel')) {
    document.querySelector('.infoPanel').style.display = 'none';
  }

                      }, 1000)


  },
  openBox(box) {
    this.allowBoxOpen = false;
    this.currentBox = box;
    
    document.querySelector('.box-1').style.opacity = 0;
    document.querySelector('.box-2').style.opacity = 0;
    document.querySelector('.box-3').style.opacity = 0;


    // document.querySelector('.logo').style.transform = 'scale(1.5)';
    document.querySelector('.logo').style.opacity = '0';

    document.querySelector(`.box-${box}`).style.opacity = 1;
    // document.querySelector('#mainBgCover').style.opacity = 0.8;

  if (document.querySelector('.infoPanel')) {
    document.querySelector('.infoPanel').style.display = 'block';
  }
    setTimeout( () => {
                    //     this.$confetti.start({
                    // particles: [
                    //   {
                    //     type: 'image',
                    //     url: './images/small-diamond.png',
                    //   }
                    // ],
                    //   defaultSize: 25,

                    // particlesPerFrame: 1,
                    
                    //});
                        document.querySelector('.infoPanel').style.opacity = 1;
                      }, 1000)

  }
}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Copse');

.loader {
  z-index: 100;
  text-align: center;
    font-size: 50px;
    color: #f2ac0a;
    position: absolute;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    top: 40%;
    transition: 1s;
}

.download-button {
  cursor: pointer;
  padding: 10px;
  background: #ffcf39;
  color: black;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  width: 380px;
  transition: 0.2s;
}

.confetti {
  cursor: pointer;
}
.refresh-button {
  cursor: pointer;
  padding: 10px;
  background: #ffcf39;
  color: black;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  left: 0;
  bottom: 0;
  position: fixed;
  transition: 0.2s;
  z-index: 999;
}

a {
    text-decoration: none;

}
.box-button {
  margin-top: 20px;
  cursor: pointer;
  transition: 0.2s;
}

.box-button:hover {
  color: white
}

.download-button:hover, .refresh-button:hover {

  color: #ffcf39;
  background: black;
}
.gift-image {
  width: 400px;
}
.infoPanel {
  opacity: 0;
  position: absolute;
  transition: 2s;
  top: 0;
  height: 100%;
  width: 500px;
  padding: 0px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}
 .infoPanel h1 {
   text-align: left;
 }
 .infoPanel p {
  color: white;
  text-align: left;
}

.left-col {
  float: left;
}
.right-col {
  padding-left: 50px;
  float: left;
  width: 400px;
      position: relative;
    z-index: 99;
}

.paused{
    -webkit-animation-play-state:paused;
    -moz-animation-play-state:paused;
    -o-animation-play-state:paused; 
    animation-play-state:paused;
}
.giftWrapper {
  z-index: 200;
  position: relative;
  opacity: 0;
  transition: 1s;
  width: 100%;
  display: flex;
  justify-content: center;
}
.gift {
  opacity: 0;
  transition: all 2s;
  position: relative;
  float: left;
  margin: 40px; 
  filter: drop-shadow(0 0 1.2rem black);
}

.logo {
  opacity: 0;
  width: 100%;
  max-width: 400px;
  transition: 2s;
  position: relative;
  z-index: 70;
}

.foreground {
  z-index: 10;
  position: relative;
}
.content {
  position: fixed;
  bottom: 20px;
  width: 100%;
  z-index: 50;
}
#app {
font-family: 'Copse', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffcf39;
  padding-top: 60px;
}
#mainBgCover {
  transition: 3s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 60;
}
#mainBgGradient {
  transition: 1s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
background: linear-gradient(0deg, rgba(0,0,0,0.6001625722543353) 11%, rgba(0,0,0,0) 31%);


}
#mainBg {
  transition: 70s;
  transform: scale(1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* filter: brightness(0); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  overflow: hidden;
}
html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}


@media only screen and (max-width: 1000px) {
.download-button  {
  width: 88%;
}
  .infoPanel {
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow-y: scroll;
    z-index: 999;
  }
  .hide-mobile {
    display: none;
  }
  .right-col {
    width: 80%;
    margin-right: 100px;
    position: relative;
    height: 100vh;
  }
  #mainBgGradient, #mainBgCover {
    position: fixed;
  }
  .giftWrapper {
    width: 250px;
    position: absolute;
    left: 50%;
    margin-top:-150px;
    margin-left: -141px;
    display: block;
  }
.gift {
  height: 150px;
}
  body, html {
    overflow: auto;
  }
}

</style>
