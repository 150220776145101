<template>
  <div>
      <div class="giftContainer">
  <div class="row">

    <div class="col-12 mt-5 d-flex justify-content-center">
      <div :class="`box box-${boxNumber}`">
        <div class="box-body" @click="openBox">
          <div class="imgflip">
          <div class="img">Open<br>Gift {{boxNumber}}</div>
          </div>
          <div class="box-lid">
            
            <div class="box-bowtie"></div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
export default {
  name: 'Gift',
    props: {
    boxNumber: String,
    openBoxParent: Function,
    canOpenBox: Boolean,
    flip: Boolean
  },
    data: function() {
      return {
        boxOpened: false,
      }
    },
  mounted() {
    if (this.flip) {
      document.querySelector(`.box-${this.boxNumber}`).style.transform = 'scaleX(-1)';
      document.querySelector(`.box-${this.boxNumber} .imgflip`).style.transform = 'scaleX(-1)';
    }
  },
  methods: {
    openBox() {
      if (!this.boxOpened && this.canOpenBox) {
        this.boxOpened = true;
        let boxBg = document.querySelector(`.box-${this.boxNumber} .box-body`)
        boxBg.className = 'box-body-open';
        this.openBoxParent(this.boxNumber)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.giftContainer {
  transform: scale(0.75);
}

@media only screen and (max-width: 1000px) {
.giftContainer {
  transform: scale(0.5);
}
}

.img {
  font-size: 40px;
  filter: drop-shadow(0 0 0.5rem black);
  height: 155px;
}
.img.opened {

  }

.box {
  position: relative;
}
.box::before {
      /* content: "";
      width: 440px;
      height: 440px;
      background-color: #89cff0;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      border-radius: 50%; */
}
.box-body, .box-body-open {
  position: relative;
  height: 200px;
  width: 200px;
  margin-top: 123.3333333333px;
  background-color: #722525;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
  background: linear-gradient(#762c2c,#94000b);
}
.box-body .img {
  opacity: 0;
  transform: translateY(0%) scaleX(-1);
  transition: all 0.5s;
  margin: 0 auto;
  display: block;
}
 .box-body-open .img {

  -webkit-animation: open-box 3s forwards ease-in-out;

 }
.box-body:hover, .box-body-open{
  cursor: pointer;
  -webkit-animation: box-body 1s forwards ease-in-out;
          animation: box-body 1s forwards ease-in-out;
}
.box-body:hover .img, .box-body-open .img{
  opacity: 1;
  z-index: 0;
  transform: translateY(-157px)!important;
}
.box-body:hover .box-lid, .box-body-open .box-lid{
  -webkit-animation: box-lid 1s forwards ease-in-out;
          animation: box-lid 1s forwards ease-in-out;
}
.box-body:hover .box-bowtie::before, .box-body-open .box-bowtie::before {
  -webkit-animation: box-bowtie-left 1.1s forwards ease-in-out;
          animation: box-bowtie-left 1.1s forwards ease-in-out;
}
.box-body:hover .box-bowtie::after, .box-body-open .box-bowtie::after{
  -webkit-animation: box-bowtie-right 1.1s forwards ease-in-out;
          animation: box-bowtie-right 1.1s forwards ease-in-out;
}
.box-body::after, .box-body-open::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 50px;
  background: linear-gradient(#e96f02,#f2ac0a);
}
.box-lid {
  position: absolute;
  z-index: 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 90%;
  height: 40px;
  background-color: #722525;
  height: 40px;
  width: 220px;
  border-radius: 5%;
  box-shadow: 0 8px 4px -4px rgba(0, 0, 0, 0.3);
}
.box-lid::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 50px;
  background: linear-gradient(#f2ac0a,#f9e6bb)
}
.box-bowtie {
  z-index: 1;
  height: 100%;
}
.box-bowtie::before, .box-bowtie::after {
  content: "";
  width: 83.3333333333px;
  height: 83.3333333333px;
  border: 16.6666666667px solid #f2ac2c;
  border-radius: 50% 50% 0 50%;
  position: absolute;
  bottom: 99%;
  z-index: -1;
}
.box-bowtie::before {
  left: 50%;
  -webkit-transform: translateX(-100%) skew(10deg, 10deg);
          transform: translateX(-100%) skew(10deg, 10deg);
}
.box-bowtie::after {
  left: 50%;
  -webkit-transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
          transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
}






.box-body-open .img {
  opacity: 0;
}



@-webkit-keyframes open-box {
  0%,
  42% {
     transform: scale(5) translateY(-50px)!important;
  }
  60% {
     transform: scale(5) translateY(-50px)!important;

  }
  90%, 100% {
    opacity: 0
  }
}

@-webkit-keyframes box-lid {
  0%,
  42% {
    -webkit-transform: translate3d(-50%, 0%, 0) rotate(0deg);
            transform: translate3d(-50%, 0%, 0) rotate(0deg);
  }
  60% {
    -webkit-transform: translate3d(-85%, -230%, 0) rotate(-25deg);
            transform: translate3d(-85%, -230%, 0) rotate(-25deg);
  }
  90%, 100% {
    -webkit-transform: translate3d(-119%, 225%, 0) rotate(-70deg);
            transform: translate3d(-119%, 225%, 0) rotate(-70deg);
  }
}

@keyframes box-lid {
  0%,
  42% {
    -webkit-transform: translate3d(-50%, 0%, 0) rotate(0deg);
            transform: translate3d(-50%, 0%, 0) rotate(0deg);
  }
  60% {
    -webkit-transform: translate3d(-85%, -230%, 0) rotate(-25deg);
            transform: translate3d(-85%, -230%, 0) rotate(-25deg);
  }
  90%, 100% {
    -webkit-transform: translate3d(-119%, 225%, 0) rotate(-70deg);
            transform: translate3d(-119%, 225%, 0) rotate(-70deg);
  }
}
@-webkit-keyframes box-body {
  0% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
            transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate3d(0%, 25%, 0) rotate(20deg);
            transform: translate3d(0%, 25%, 0) rotate(20deg);
  }
  50% {
    -webkit-transform: translate3d(0%, -15%, 0) rotate(0deg);
            transform: translate3d(0%, -15%, 0) rotate(0deg);
  }
  70% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
            transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
}
@keyframes box-body {
  0% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
            transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate3d(0%, 25%, 0) rotate(20deg);
            transform: translate3d(0%, 25%, 0) rotate(20deg);
  }
  50% {
    -webkit-transform: translate3d(0%, -15%, 0) rotate(0deg);
            transform: translate3d(0%, -15%, 0) rotate(0deg);
  }
  70% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
            transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
}
@-webkit-keyframes box-bowtie-right {
  0%,
  50%,
  75% {
    -webkit-transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
            transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
  }
  90%,
  100% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
            transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.3);
  }
}
@keyframes box-bowtie-right {
  0%,
  50%,
  75% {
    -webkit-transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
            transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
  }
  90%,
  100% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
            transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.3);
  }
}
@-webkit-keyframes box-bowtie-left {
  0% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
            transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
  50%,
  75% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
            transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
  }
  90%,
  100% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
            transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
}
@keyframes box-bowtie-left {
  0% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
            transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
  50%,
  75% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
            transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
  }
  90%,
  100% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
            transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
}

</style>
